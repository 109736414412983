$primary-color: #1b4686;
$secondary-blue-color: #2597f3;
$app-background: #e0e0e0;
$white: #fff;
$black: #000000;
$success-color: #26a172;
$primary-font-color: #41413f;
$secondary-font-color: #828282;
$primary-border-color: rgba(65, 65, 63, 0.2);
$secondary-border-color: #ccc;
$font-family: 'helvetica, sans-serif';
$third-blue-color: #345c97;
$fourth-blue-color: #225a99;
$warning-orange-color: #e84d52;
$secondary-driver-quote-color: #757575;
$header-color: #0b184e;
$body-color: #f7f7f7;
$font-color-red: #eb3047;
$font-color-light: #1895f6;
$font-color-light-green: #2ab364;
$primary-light-green: #d4f0e0;
$primary-light-blue: #1895f6;
$primary-gray-color: #d8d8d8;
$primary-gray-background: #d1eafd;
$faded-blue-background: #77bbf2;
$faint-blue-shadow-background: #e0ecff;
$ribbon-orange: #e82;
$ribbon-green: #2c7;
