@import './spacing_variables';

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.full-width {
  width: 100%;
}

// Padding extra small
.padding-xs {
  padding: $padding-xs;
}

.padding-top-xs {
  padding-top: $padding-xs;
}

.padding-right-xs {
  padding-right: $padding-xs;
}

.padding-bottom-xs {
  padding-bottom: $padding-xs;
}

.padding-left-xs {
  padding-left: $padding-xs;
}

// Padding small
.padding-sm {
  padding: $padding-sm;
}

.padding-top-sm {
  padding-top: $padding-sm;
}

.padding-right-sm {
  padding-right: $padding-sm;
}

.padding-bottom-sm {
  padding-bottom: $padding-sm;
}

.padding-left-sm {
  padding-left: $padding-sm;
}

// Padding medium
.padding-md {
  padding: $padding-md;
}

.padding-top-md {
  padding-top: $padding-md;
}

.padding-right-md {
  padding-right: $padding-md;
}

.padding-bottom-md {
  padding-bottom: $padding-md;
}

.padding-left-md {
  padding-left: $padding-md;
}

// Padding large
.padding-lg {
  padding: $padding-lg;
}

.padding-top-lg {
  padding-top: $padding-lg;
}

.padding-right-lg {
  padding-right: $padding-lg;
}

.padding-bottom-lg {
  padding-bottom: $padding-lg;
}

.padding-left-lg {
  padding-left: $padding-lg;
}

// Padding extra large
.padding-xlg {
  padding: $padding-xlg;
}

.padding-top-xlg {
  padding-top: $padding-xlg;
}

.padding-right-xlg {
  padding-right: $padding-xlg;
}

.padding-bottom-xlg {
  padding-bottom: $padding-xlg;
}

.padding-left-xlg {
  padding-left: $padding-xlg;
}

// Margin extra small
.margin-xs {
  margin: $padding-xs;
}

.margin-top-xs {
  margin-top: $padding-xs;
}

.margin-right-xs {
  margin-right: $padding-xs;
}

.margin-bottom-xs {
  margin-bottom: $padding-xs;
}

.margin-left-xs {
  margin-left: $padding-xs;
}

// Margin small
.margin-sm {
  margin: $padding-sm;
}

.margin-top-sm {
  margin-top: $padding-sm;
}

.margin-right-sm {
  margin-right: $padding-sm;
}

.margin-bottom-sm {
  margin-bottom: $padding-sm;
}

.margin-left-sm {
  margin-left: $padding-sm;
}

// Margin medium
.margin-md {
  margin: $padding-md;
}

.margin-top-md {
  margin-top: $padding-md;
}

.margin-right-md {
  margin-right: $padding-md;
}

.margin-bottom-md {
  margin-bottom: $padding-md;
}

.margin-left-md {
  margin-left: $padding-md;
}

// Margin large
.margin-lg {
  margin: $padding-lg;
}

.margin-top-lg {
  margin-top: $padding-lg;
}

.margin-right-lg {
  margin-right: $padding-lg;
}

.margin-bottom-lg {
  margin-bottom: $padding-lg;
}

.margin-left-lg {
  margin-left: $padding-lg;
}

// Margin extra large
.margin-xlg {
  margin: $padding-xlg;
}

.margin-top-xlg {
  margin-top: $padding-xlg;
}

.margin-right-xlg {
  margin-right: $padding-xlg;
}

.margin-bottom-xlg {
  margin-bottom: $padding-xlg;
}

.margin-left-xlg {
  margin-left: $padding-xlg;
}

// Deprecated spacing
.padding-top-x1 {
  padding-top: $padding;
}

.padding-top-x2 {
  padding-top: $padding * 2;
}

.padding-top-x4 {
  padding-top: $padding * 4;
}

.padding-bottom-x1 {
  padding-bottom: $padding;
}

.padding-bottom-x2 {
  padding-bottom: $padding * 2;
}

.padding-bottom-x3 {
  padding-bottom: $padding * 3;
}

.padding-bottom-x4 {
  padding-bottom: $padding * 4;
}

.padding-left-4x {
  padding-left: $padding * 4;
}

.padding-right-2x {
  padding-right: $padding * 2;
}

.padding-sides-1x {
  padding-left: $padding;
  padding-right: $padding;
}

.padding-sides-2x {
  padding-left: $padding * 2;
  padding-right: $padding * 2;
}

.padding-ends {
  padding-top: $padding;
  padding-bottom: $padding;
}

.padding-ends-x4 {
  padding-top: $padding * 4;
  padding-bottom: $padding * 4;
}

.padding {
  padding: $padding;
}

.margin-bottom {
  margin-bottom: $padding;
}

.margin-bottom-x2 {
  margin-bottom: $padding * 2;
}

.margin-bottom-x3 {
  margin-bottom: $padding * 3;
}

.margin-right-x1 {
  margin-right: $padding;
}

.margin-right-x2 {
  margin-right: $padding * 2;
}
