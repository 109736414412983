@import 'colors_variables';

.success {
  color: $success-color;
}

.secondary-color {
  color: $secondary-font-color;
}

.secondary-blue-color {
  color: $secondary-blue-color;
}

// Toast Component 
.toast-success {
  background-color: green;
  color: $white;
}

.toast-info {
  background-color: blue;
  color: $white;
}

.toast-warning {
  background-color: orange;
  color: $white;
}

.toast-error{
  background-color: red;
  color: $white;
}
.primary-color {
  color: $primary-font-color;
}
