/* You can add global styles to this file, and also import other style files */
@import "fleetoperate-theme";
@import "colors_variables";
@import "spacing_variables";

html {
  height: 100%;

  body {
    min-height: 100%;
    height: 100%;
    margin: 0;

    background-color: $body-color;
    color: $primary-font-color;
  }
}

.brand-title {
  height: 80px;
  font-size: 20pt;

  img {
    height: 45px;
    margin-top: 20px;
  }
}

fleetoperate-fleetoperate-toolbar + * {
  padding-top: $padding-md * 4;
}
