@import "colors_variables";
@import "shipment_variables";

.shipment-chip {
    padding: 5px;
    border-radius: 4px;
    display: inline-block;
    font-weight: 500;
}

.shipment-booked {
    background-color: $shipment-booked-background-color;
    color: $shipment-status-black-font;
}

.shipment-bill-of-lading-available {
    background-color: $shipment-bill-of-lading-available-background-color;
    color: $shipment-status-black-font;
}

.shipment-confirmation-pending {
    background-color: $shipment-confirmation-pending-background-color;
    color: $shipment-status-black-font;
}

.shipment-draft {
    background-color: $shipment-draft-background-color;
    color: $shipment-status-black-font;
}

.shipment-unsecured {
    background-color: $shipment-unsecured-background-color;
    color: $shipment-status-black-font;
}

.shipment-out-for-delivery {
    background-color: $shipment-out-for-delivery-background-color;
    color: $shipment-status-white-font;
}

.shipment-delivered {
    background-color: $shipment-delivered-background-color;
    color: $shipment-status-white-font;
}

.shipment-completed {
    background-color: $shipment-completed-background-color;
    color: $shipment-status-white-font;
}

.shipment-inactive {
    background-color: $shipment-inactive-background-color;
    color: $shipment-status-white-font;
}

.shipment-cancelled {
    background-color: $shipment-cancelled-background-color;
    color: $shipment-status-white-font;
}
