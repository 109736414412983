@import "colors_variables";

$shipment-status-black-font: $primary-font-color;

$shipment-status-white-font: $white;

$shipment-booked-background-color: #9ccc65;

$shipment-bill-of-lading-available-background-color: #9ccc65;

$shipment-confirmation-pending-background-color: #00e5ff;

$shipment-draft-background-color: #ffff00;

$shipment-unsecured-background-color: #ffd600;

$shipment-out-for-delivery-background-color: #009688;

$shipment-delivered-background-color: #004d40;

$shipment-completed-background-color: #1a237e;

$shipment-inactive-background-color: #f4511e;

$shipment-cancelled-background-color: #ba68c8;
